@import url("https://use.typekit.net/tzc8sty.css%22");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "freight-text-pro", serif;
  font-weight: 400;
}

p {
  font-family: "freight-sans-pro", sans-serif;
}
